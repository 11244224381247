// Common styles used by all icons in HTML and CSS
@mixin icon {
  fill: $black;
  display: block;
  width: 35px;
  height: 35px;
  color: $black;
  &:hover {
    fill: $color-grey;
  }
}

@mixin svg-icon-inline-mask($filename) {
  -webkit-mask: inline-image('icons/src/#{$filename}');
  mask: inline-image('icons/src/#{$filename}');
}
