@import '../../scss/theme-bootstrap';

.elc-search-results-wrapper,
.elc-search-enhanced-grid-wrapper,
.sd-product-grid {
  @include container-max-width-xxl;
  font-family: $font--text;
  min-height: 500px;
  margin: 20px auto 0;
  .elc-product-grid-header {
    font-family: $font--pretendard-regular;
    font-size: 27px;
    line-height: 1;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      margin: 20px 0;
    }
  }
  .elc-sort-wrapper {
    @include swap_direction(margin, 0 20px 0 auto);
    border: 1px solid $color-light-gray;
    width: 42%;
    @include breakpoint($landscape-up) {
      flex-grow: 0;
      width: auto;
      border: none;
      min-width: 180px;
      order: 3;
    }
    .elc-dropdown {
      &-arrow {
        border: none;
        outline: none;
        &:hover {
          background: none;
        }
        &:focus {
          outline: auto;
        }
      }
      &-options-list {
        border: 1px solid $color-black;
      }
      &-readonly-input {
        color: $color-black;
        font-family: $font--text-main;
        font-size: 14px;
        justify-content: flex-start;
        text-transform: uppercase;
      }
    }
    select,
    .elc-dropdown-option {
      font-family: $font--text-main;
      color: $color-black;
      font-size: 14px;
      line-height: 22px;
      padding: 5px 10px;
      height: 44px;
      text-transform: uppercase;
    }
    .elc-dropdown-option {
      padding: 5px 10px;
    }
    select {
      flex-grow: 1;
      appearance: auto;
      background-color: $color-white;
      width: 150px;
      @include breakpoint($landscape-up) {
        width: 170px;
      }
      background-image: none;
      border: none;
    }
    label {
      display: flex;
    }
  }
  .elc-product-grid {
    &--header {
      box-shadow: none;
      margin: 15px 0;
    }
  }
  .elc-grid-container {
    margin: 0 0 20px;
    @include breakpoint($landscape-up) {
      margin: 0 10px;
    }
    .elc-slider-view-wrapper {
      .slick-slide {
        margin: 0 15px;
      }
    }
    .elc-grid-item-tout,
    .elc-grid-item-product {
      padding: 0 15px 30px;
      position: relative;
      @include breakpoint($landscape-up) {
        margin: 0 15px 80px;
        padding: 0;
      }
    }
    .elc-grid-item-tout {
      @include breakpoint($landscape-up) {
        padding-bottom: 0;
      }
      .elc-product-images-wrapper {
        .elc-img {
          @include breakpoint($landscape-up) {
            position: absolute;
          }
        }
      }
    }
  }
  .elc-product-carousel-slider-view {
    margin: 0;
    .slick-slider {
      .slick-arrow {
        top: 30%;
        &.slick-prev {
          #{$ldirection}: -20px;
        }
        &.slick-next {
          #{$rdirection}: -20px;
        }
      }
      .elc-icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .skeleton-mobile-2,
  [data-num-columns-mobile="2"] {
    .elc-grid-item-tout {
      @include breakpoint($small-down) {
        grid-column: span 2;
      }
    }
  }
}

.breadcrumbs {
  @include container-max-width-xxl;
  background-color: $color-white;
  padding: 30px 20px 10px;
  @include breakpoint($landscape-up) {
    margin: 0 auto;
    padding: 30px 25px 10px;
  }
  &__level {
    font-size: 14px;
    text-decoration: none;
    &:before {
      content: '/';
    }
    &:last-child:before {
      content: '';
    }
    a {
      font-family: $font--text;
      border-bottom: 1px solid transparent;
      color: $color-black;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      text-decoration: none;
      text-transform: inherit;
      &:hover {
        color: $color-black;
        border-bottom: 1px solid $color-black;
      }
    }
    h1 {
      display: inline-block;
      font-size: 14px;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      text-transform: inherit;
    }
  }
}

// MPP Filters
.sd-product-grid,
.elc-mobile-filters-modal {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 360px;
  .elc-product-grid {
    &--header {
      // specificity for backwards compatibility override
      .elc-product-grid--filters-section ~ div.elc-sort-wrapper {
        @include swap_direction(padding, 0 29px 0 0);
        display: flex;
        flex: initial;
        top: unset;
        &::after {
          background: $color-black;
          bottom: 0;
          content: '';
          display: inline-block;
          height: 16px;
          margin: auto 0;
          position: absolute;
          #{$rdirection}: 0;
          top: 0;
          transform: rotate(90deg);
          width: 16px;
        }
        select {
          @include ternary($text-direction == ltr, direction, rtl, ltr);
          background: none;
          color: $color-black;
          option {
            direction: $text-direction;
          }
        }
        @include breakpoint($landscape-up) {
          position: absolute;
          top: 10px;
          padding: 0;
          &::after {
            content: none;
          }
        }
      }
      .elc-orderable-filters-wrapper {
        width: 42%;
        position: relative;
        z-index: 2;
        @include breakpoint($landscape-up) {
          width: 100%;
        }
      }
      .elc-collapsible-wrapper {
        display: flex;
        position: absolute;
        width: 100%;
        flex-wrap: wrap;
        z-index: 1;
        background: $color-white;
        padding-top: 100px;
        top: 0;
        &::before {
          content: '';
          position: absolute;
          width: auto;
          border-bottom: 1px solid $color-light-gray;
          top: 75px;
          #{$rdirection}: 20px;
          #{$ldirection}: 20px;
        }
        + .elc-product-grid--filters-modal-button {
          &::before {
            background: url('#{$base-theme-path}img/icons/src/close.svg');
            height: 10px;
            width: 10px;
            #{$rdirection}: 15px;
            top: 15px;
          }
        }
        .elc-overlay-wrapper-close-button {
          display: none;
        }
      }
    }
    &--filters-section {
      margin: 0;
      @include breakpoint($landscape-up) {
        width: 100%;
        position: relative;
      }
      .elc-filter-by-label {
        @include sub-heading;
        padding: 0 20px;
        text-transform: uppercase;
      }
    }
    &--apply-filter {
      @include elc-button;
      @include elc-button--dark;
      #{$rdirection}: 20px;
    }
    &--filters-modal-button,
    &--clear-all {
      @include elc-button;
      @include elc-button--light;
      #{$rdirection}: 20px;
    }
    &--filters-modal-button,
    &--clear-all,
    &--apply-filter {
      height: 46px;
      width: 42%;
      font-size: 14px;
      padding: 15px 16px;
      top: auto;
      max-width: auto;
      position: fixed;
      #{$ldirection}: auto;
      bottom: 22px;
      z-index: 20;
      @include breakpoint($landscape-up) {
        position: absolute;
        bottom: auto;
        width: auto;
        top: 0;
        z-index: inherit;
      }
      &-label {
        font-family: $font--button;
        font-size: 14px;
        line-height: 15px;
        color: $color-white;
      }
    }
    &--filters-modal-button {
      border-color: $color-medium-light-grey;
      font-family: $font--text-main;
      #{$rdirection}: auto;
      text-align: $ldirection;
      z-index: 2;
      position: relative;
      top: 0;
      width: 100%;
      #{$ldirection}: 20px;
      overflow: hidden;
      @include breakpoint($landscape-up) {
        position: absolute;
        #{$ldirection}: 20px;
        width: 185px;
      }
      &::after {
        display: none;
      }
      &::before {
        background: url('#{$base-theme-path}img/icons/src/filter.svg');
        height: 54px;
        width: 54px;
        position: absolute;
        content: '';
        top: -2px;
        #{$rdirection}: -10px;
        background-repeat: no-repeat;
      }
      &:hover,
      &:focus {
        border-color: $color-medium-light-grey;
        background-color: $color-white;
      }
      .elc-filter-icon {
        display: none;
      }
    }
    &--clear-all {
      #{$ldirection}: 20px;
      margin: 0;
      #{$rdirection}: 200px;
      @include breakpoint($landscape-up) {
        #{$ldirection}: auto;
      }
      .elc-icon {
        display: none;
      }
    }
    &--filter-set {
      @include body-text;
      position: relative;
      align-items: center;
      background: transparent;
      color: $color-black;
      line-height: 1;
      text-align: #{$ldirection};
      text-transform: uppercase;
      width: 100%;
      padding: 0 22px;
      justify-content: space-between;
      display: flex;
      &:active {
        background-color: transparent;
      }
      @include breakpoint($landscape-up) {
        @include body-text;
        @include swap_direction(padding, 10px 20px 10px 0);
        height: 40px;
        text-transform: capitalize;
        display: inline-block;
        &:focus {
          outline: none;
        }
      }
      &:hover {
        background: transparent;
      }
      &::after {
        @include svg-icon-inline-mask('plus.svg');
        background: $color-black;
        color: $color-black;
        content: '';
        display: flex;
        height: 16px;
        width: 16px;
        margin-#{$ldirection}: 9px;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      &.active {
        @include breakpoint($landscape-up) {
          color: $color-light-gray;
        }
        &::after {
          @include svg-icon-inline-mask('minus.svg');
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
      }
      &-wrapper {
        display: inline-block;
        padding: 15px 0 0;
        width: 100%;
        border-bottom: 1px solid $color-light-gray;
        @include breakpoint($landscape-up) {
          @include swap_direction(padding, 0 25px 0 20px);
          width: auto;
          border: none;
        }
        label {
          font-family: $font--pretendard-semi-bold;
          font-size: 12px;
          margin-bottom: 12px;
          @include breakpoint($landscape-up) {
            font-size: 14px;
            margin-bottom: 18px;
          }
        }
      }
      .elc-styled-filters-active-filters-no {
        @include swap_direction(padding, 0 0 0 6px);
        flex: 1;
      }
    }
    &--filter-options-wrapper {
      background-color: $color-white;
      padding: 0;
      width: 100%;
      display: inline-block;
      @include breakpoint($landscape-up) {
        display: grid;
        grid-template-rows: auto auto auto auto;
        grid-gap: 0;
        padding: 0;
        grid-auto-flow: column;
        width: 100%;
      }
    }
    &--filter-option-checkbox {
      width: 50%;
      float: $ldirection;
      margin: 5px 0 10px;
      @include breakpoint($landscape-up) {
        width: 100%;
        min-width: 150px;
        padding-#{$rdirection}: 50px;
        height: 32px;
        margin: 0 0 16px;
      }
      &.no-results {
        cursor: default;
        .elc-checkbox-icon {
          cursor: default;
        }
      }
      .elc-checkbox {
        display: flex;
        &-label {
          font-family: $font--label;
          font-size: 14px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          height: 20px;
          text-transform: none;
        }
        &-check-area {
          background-color: $white;
          display: inline-flex;
          height: 20px;
          margin-#{$rdirection}: 5px;
          overflow: hidden;
          top: unset;
          width: 20px;
        }
        &-selected {
          .elc-checkbox-icon {
            z-index: 0;
            background-color: $color-darkest-grey;
          }
        }
      }
    }
  }
}

.elc-slider-view-wrapper {

  #{$sd-grid-2-up-selector} {
    .slick-list {
      .slick-track {
        .slick-slide {
          margin: 0;
          @include breakpoint($landscape-up) {
            margin: 0 15px;
          }
        }
      }
    }
  }
}
.elc-responsive-modal-mask .elc-mobile-filters-modal {
  transform: none;
  #{$ldirection}: auto;
  top: auto;
  height: 100%;
  padding-bottom: 50px;
  overflow: scroll;
  .elc-overlay-wrapper-close-button {
    #{$rdirection}: 15px;
    background-color: transparent;
    position: absolute;
    top: 20px;
    width: auto;
    min-width: auto;
    .elc-remove-icon {
      height: 15px;
      width: 15px;
    }
  }
  .elc-filter-by-label {
    @include main-heading;
    color: inherit;
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }
}

.sd-mounted {
  overflow-x: hidden;
  @include breakpoint($landscape-up) {
    overflow-x: initial;
  }
}
